@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Brand colors:
#3A2954
#194534
#C1C6C8
#EFC367
*/

/* Tables */
.athmn-table {
  @apply min-w-full divide-y divide-gray-200;
}

.athmn-table thead {
  @apply bg-gray-50;
}

.athmn-table thead th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.athmn-table tbody {
  @apply bg-white divide-y divide-gray-200;
}

.athmn-table tbody td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}

.athmn-table tbody tr:hover {
  @apply hover:bg-gray-100;
}

/* Colors */
.athmn-bg-primary {
  background-color: #3A2954;
}

.athmn-bg-secondary {
  background-color: #194534;
}

.athmn-bg-gray {
  background-color: #c1c6c8;
}

.athmn-bg-yellow {
  background-color: #efc367;
}

.athmn-text-primary {
  color: #3A2954;
}

.athmn-text-secondary {
  color: #194534;
}

.athmn-text-gray {
  color: #c1c6c8;
}

.athmn-text-yellow {
  color: #efc367;
}

/* Inclusive Dates */
.inclusive-dates__label,
.inclusive-dates__quick-group,
.inclusive-dates__calendar-button,
inclusive-dates br {
  @apply hidden;
}

.inclusive-dates__input-container {
  @apply flex items-center space-x-2;
}

.inclusive-dates__input {
  @apply block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-6;
}